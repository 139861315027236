<template>
	<div class="w-full px-3 py-2 rounded-sm bg-yellow-700 grid grid-cols-7 gap-3">
		<div v-if="!isNew && !editing" class="text-white">
			{{ receiver.name }}
		</div>
		<TextInput
			v-else
			label="Name"
			v-model="editedReceiver.name"
			@update:model="editedReceiver.name = $event"
		/>
		<div v-if="!isNew && !editing" class="text-white">
			{{ receiver.email }}
		</div>
		<TextInput
			v-else
			label="Email"
			v-model="editedReceiver.email"
			@update:model="editedReceiver.email = $event"
		/>
		<div v-if="!isNew && !editing" class="text-white">
			{{ receiver.webDomain }}
		</div>
		<TextInput
			v-else
			label="Web Domain"
			v-model="editedReceiver.webDomain"
			@update:model="editedReceiver.webDomain = $event"
		/>
		<div class="text-white">
			{{ receiver.authKey }}
		</div>
		<div class="text-white">
			{{ parseDate(receiver.createdAt) }}
		</div>
		<div class="text-white">
			{{ parseDate(receiver.updatedAt) }}
		</div>

		<div v-if="isNew || editing" class="grid grid-flow-col gap-3">
			<Btn color="green" @click="save">
				<span class="text-white">
					Save
				</span>
			</Btn>
			<Btn color="red" @click="cancel">
				<span class="text-white">
					Cancel
				</span>
			</Btn>
		</div>
		<div v-else class="grid grid-flow-col gap-3">
			<Btn color="green" @click="editing = true">
				<span class="text-white">
					Edit
				</span>
			</Btn>
			<Btn color="red" @click="deleteReceiver">
				<span class="text-white">
					Delete
				</span>
			</Btn>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import Btn from '@/components/Btn.vue';
import HttpManager from '@/utils/HttpManager.js';

export default {
	name: 'ReceiverComponent',
	props: {
		receiver: {
			type: Object,
			default: () => ({}),
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		TextInput,
		Btn,
	},
	emits: [
		'reload',
		'update',
		'cancel',
	],
	data() {
		return {
			editedReceiver: {
				id: this.receiver.id,
				name: this.receiver.name,
				email: this.receiver.email,
				webDomain: this.receiver.webDomain,
			},

			editing: false,
		};
	},
	methods: {
		async deleteReceiver() {
			const check = await window.confirm('Are you sure you want to delete this receiver?');

			if (!check) {
				return;
			}

			const response = await HttpManager.post(`/receiver/delete`, this.receiver);
			
			if (response.data?.data?.success) {
				this.$emit('reload');
			} else {
				alert(response.data?.data?.error);
			}
		},
		save() {
			this.$emit('update', this.editedReceiver);
			this.editing = false;
		},
		cancel() {
			if (this.isNew) {
				this.$emit('cancel');
			} else {
				this.editing = false;
				this.editedReceiver = {
					id: this.receiver.id,
					name: this.receiver.name,
					email: this.receiver.email,
					webDomain: this.receiver.webDomain,
				};
			}
		},
		parseDate(date) {
			if (!date) {
				return '';
			}

			const d = new Date(date.split('T')[0]).toLocaleDateString().split('/');

			return `${d[1]}.${d[0]}.${d[2]}`;
		},
	},
};
</script>