<template>
	<button
		:style="{
			backgroundColor: color,
			opacity: disabled ? 0.6 : 1,
		}"
		class="w-full py-1 rounded-md shadow-sm transition-all"
		:class="{
			[border]: border,
			'hover:scale-[1.1] active:scale-[0.95]': !disabled,
		}"
		:disabled="disabled"
	>
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: 'CustomButton',
	props: {
		color: {
			type: String,
			default: 'green',
		},
		border: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
};
</script>