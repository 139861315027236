<template>
	<div class="w-screen grid relative p-4">
		<h1 class="text-xl">Simple Emailer Receivers</h1>

		<a href="#" class="absolute top-1 right-1" @click="logout">
			<span class="text-xs">
				Logout
			</span>
		</a>

		<Btn
			v-if="!creatingNew"
			color="green"
			class="mt-4 max-w-64"
			@click="creatingNew = true"
		>
			<span class="text-white">
				Create Receiver
			</span>
		</Btn>
		<Receiver
			v-else
			isNew
			@update="create($event)"
			@cancel="creatingNew = false"
		/>

		<div class="grid gap-2 w-full mt-2">
			<Receiver
				v-for="receiver in receivers"
				:key="receiver.id"
				:receiver="receiver"
				@reload="getReceivers"
				@update="updateReceiver"
			/>
		</div>
	</div>
</template>

<script>
import HttpManager from '@/utils/HttpManager.js';
import Btn from '@/components/Btn.vue';
import Receiver from '@/components/Receiver.vue';

export default {
	name: 'HomeView',
	components: {
		Btn,
		Receiver,
	},
	data() {
		return {
			receivers: [],
			loading: true,

			creatingNew: false,
		};
	},
	mounted() {
		this.getReceivers();
	},
	methods: {
		async getReceivers() {
			this.loading = true;
			const response = await HttpManager.get('/receiver');
			this.receivers = response.data.data;
			this.loading = false;
		},
		logout() {
			HttpManager.logout();
			this.$router.push('/login');
		},
		async create(receiver) {
			this.loading = true;
			const response = await HttpManager.post('/receiver', receiver);
			
			if (response.data?.data?.success) {
				this.getReceivers();
				this.creatingNew = false;
			} else {
				alert(response.data?.data?.error);
			}

			this.loading = false;
		},
		async updateReceiver(receiver) {
			this.loading = true;
			const response = await HttpManager.post(`/receiver/update`, receiver);
			
			if (response.data?.data?.success) {
				this.getReceivers();
			} else {
				alert(response.data?.data?.error);
			}

			this.loading = false;
		},
	},
}
</script>