import axios from 'axios';

const localStorageKey = 'simple-emailer-token';

const axiosInstance = axios.create({
	baseURL: '/api',
});

axiosInstance.interceptors.request.use(config => {
	const token = localStorage.getItem(localStorageKey);
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
}, error => {
	return Promise.reject(error);
});

export default {
	get(url, config = {}) {
		try {
			return axiosInstance.get(url, config);
		} catch (error) {
			console.error(error);
		}
	},

	post(url, data, config = {}) {
		try {
			return axiosInstance.post(url, data, config);
		} catch (error) {
			console.error(error);
		}
	},

	isUserLoggedIn() {
		return localStorage.getItem(localStorageKey) !== null;
	},

	saveToken(token) {
		localStorage.setItem(localStorageKey, token);
	},

	logout() {
		localStorage.removeItem(localStorageKey);
	},
};
