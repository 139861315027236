import HttpManager from './utils/HttpManager';
import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue';
import Home from './views/Home.vue';
import NotFound from './views/NotFound.vue';

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: { 
			requiresAuth: false,
		},
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
		},
	},
	{ 
		path: '/:pathMatch(.*)*', 
		name: 'NotFound', 
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const isLoggedIn = HttpManager.isUserLoggedIn();

	if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
		next('/login');
	} else if (to.matched.some(record => !record.meta.requiresAuth) && isLoggedIn) {
		next('/');
	} else {
		next();
	}
});

export default router;
