<template>
	<div class="w-screen h-screen grid place-content-center">
		<div class="p-4 space-y-4 w-full">
			<TextInput 
				label="Email" 
				v-model="email"
				@update:model="email = $event"
			/>
			<TextInput
				label="Password"
				v-model="password"
				@update:model="password = $event"
				:secure="true"
			/>
			<Btn type="submit" color="green" :disabled="loading" @click="login">
				<span class="text-white">
					Login
				</span>
			</Btn>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import Btn from '@/components/Btn.vue';
import HttpManager from '@/utils/HttpManager.js';

export default {
	name: 'LoginView',
	components: {
		TextInput,
		Btn,
	},
	data() {
		return {
			email: '',
			password: '',

			loading: false,
		};
	},
	methods: {
		async login() {
			this.loading = true;

			const response = await HttpManager.post('/auth/login', {
				email: this.email,
				password: this.password,
			});

			if (response.data?.data?.token) {
				HttpManager.saveToken(response.data.data.token);
				this.$router.push('/');
			} else {
				alert('Invalid email or password');
			}

			this.loading = false;
		},
	}
}
</script>